@import "./themes.scss";

body {
  font-family: "Raleway", sans-serif !important;
  background-image: url("./assets/img/paper2.jpg");
}

a {
  @include themify("color", "nav-text");
}

.is-outlined {
  border: 1px solid black;
  background-color: transparent;
}

.is-underlined {
  text-decoration: underline;
}

.headline {
  font-family: "Shadows Into Light", cursive;
  line-height: 1;
  margin-bottom: 1rem;
}

.box {
  position: relative;
}

.button.more-details {
  position: absolute;
  z-index: 10;
  background: none;
  border: none;
  top: -1.3rem;
  left: -1.8rem;
  font-size: 2rem;
}

.button.more-details:hover {
  color: grey;
}
/*
    .box.background {
      background-image: url(/img/weber/mes.png);
    }
    */
.is-divider,
.is-divider-vertical {
  display: block;
  position: relative;
  border-top: 0.1rem solid #dbdbdb;
  height: 0.1rem;
  margin: 2rem 0;
  text-align: center;
}

.is-divider-vertical::before {
  border-left: 0.1rem solid #dbdbdb;
  bottom: 1rem;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 1rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ScrollUpButton__Container,
.ScrollUpButton__Toggled {
  background-color: rgba(220, 220, 220, 0.6) !important;
  padding: 10px !important;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}
