[data-tooltip]:not(.is-loading),
[data-tooltip]:not(.is-disabled),
[data-tooltip]:not([disabled]) {
  cursor: pointer;
  overflow: visible;
  position: relative;
}

[data-tooltip]:not(.is-loading):focus::before,
[data-tooltip]:not(.is-loading):focus::after,
[data-tooltip]:not(.is-loading):hover::before,
[data-tooltip]:not(.is-loading):hover::after,
[data-tooltip]:not(.is-loading).has-tooltip-active::before,
[data-tooltip]:not(.is-loading).has-tooltip-active::after,
[data-tooltip]:not(.is-disabled):focus::before,
[data-tooltip]:not(.is-disabled):focus::after,
[data-tooltip]:not(.is-disabled):hover::before,
[data-tooltip]:not(.is-disabled):hover::after,
[data-tooltip]:not(.is-disabled).has-tooltip-active::before,
[data-tooltip]:not(.is-disabled).has-tooltip-active::after,
[data-tooltip]:not([disabled]):focus::before,
[data-tooltip]:not([disabled]):focus::after,
[data-tooltip]:not([disabled]):hover::before,
[data-tooltip]:not([disabled]):hover::after,
[data-tooltip]:not([disabled]).has-tooltip-active::before,
[data-tooltip]:not([disabled]).has-tooltip-active::after {
  opacity: 1;
  visibility: visible;
}

[data-tooltip]:not(.is-loading)::before,
[data-tooltip]:not(.is-disabled)::before,
[data-tooltip]:not([disabled])::before {
  top: 0;
  right: auto;
  bottom: auto;
  left: 40%;
  margin-top: -5px;
  margin-bottom: auto;
  transform: translate(-50%, -100%);
}

[data-tooltip]:not(.is-loading)::before,
[data-tooltip]:not(.is-disabled)::before,
[data-tooltip]:not([disabled])::before {
  background: rgba(74, 74, 74, 0.6);
  border-radius: 2px;
  content: attr(data-tooltip);
  padding: 0.5rem 1rem;
  text-overflow: ellipsis;
  white-space: pre;
}

[data-tooltip]:not(.is-loading)::before,
[data-tooltip]:not(.is-loading)::after,
[data-tooltip]:not(.is-disabled)::before,
[data-tooltip]:not(.is-disabled)::after,
[data-tooltip]:not([disabled])::before,
[data-tooltip]:not([disabled])::after {
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  font-size: 0.75rem;
  hyphens: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  visibility: hidden;
  z-index: 1020;
}

[data-tooltip]:not(.is-loading):focus::before,
[data-tooltip]:not(.is-loading):focus::after,
[data-tooltip]:not(.is-loading):hover::before,
[data-tooltip]:not(.is-loading):hover::after,
[data-tooltip]:not(.is-loading).has-tooltip-active::before,
[data-tooltip]:not(.is-loading).has-tooltip-active::after,
[data-tooltip]:not(.is-disabled):focus::before,
[data-tooltip]:not(.is-disabled):focus::after,
[data-tooltip]:not(.is-disabled):hover::before,
[data-tooltip]:not(.is-disabled):hover::after,
[data-tooltip]:not(.is-disabled).has-tooltip-active::before,
[data-tooltip]:not(.is-disabled).has-tooltip-active::after,
[data-tooltip]:not([disabled]):focus::before,
[data-tooltip]:not([disabled]):focus::after,
[data-tooltip]:not([disabled]):hover::before,
[data-tooltip]:not([disabled]):hover::after,
[data-tooltip]:not([disabled]).has-tooltip-active::before,
[data-tooltip]:not([disabled]).has-tooltip-active::after {
  opacity: 1;
  visibility: visible;
}

[data-tooltip]:not(.is-loading)::after,
[data-tooltip]:not(.is-disabled)::after,
[data-tooltip]:not([disabled])::after {
  top: 0;
  right: auto;
  bottom: auto;
  left: 40%;
  margin-top: -5px;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: -5px;
  border-color: rgba(74, 74, 74, 0.6) transparent transparent transparent;
}

[data-tooltip]:not(.is-loading)::after,
[data-tooltip]:not(.is-disabled)::after,
[data-tooltip]:not([disabled])::after {
  content: "";
  border-style: solid;
  border-width: 6px;
  border-color: rgba(74, 74, 74, 0.6) transparent transparent transparent;
  margin-bottom: -5px;
}

[data-tooltip]:not(.is-loading)::before,
[data-tooltip]:not(.is-loading)::after,
[data-tooltip]:not(.is-disabled)::before,
[data-tooltip]:not(.is-disabled)::after,
[data-tooltip]:not([disabled])::before,
[data-tooltip]:not([disabled])::after {
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  font-size: 0.75rem;
  hyphens: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  visibility: hidden;
  z-index: 1020;
}
