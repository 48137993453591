.player-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
