.react-photo-gallery--gallery > div {
  align-items: flex-start; /* center | flex-end | etc... */
}

.project-container {
  margin-top: 2rem;
}

.brandLogo {
  width: 20vw;
  margin: 4rem auto;
}

.projectList {
  list-style-type: disc;
  padding-left: 30px;
}

.postcard {
  width: 100%;
  height: 100%;
  box-shadow: 2px 2px 4px grey;
  margin-bottom: 2rem;
  position: relative;
  transition: transform 0.7s;
  transform-style: preserve-3d;
}

.postcard-front,
.postcard-back {
  position: absolute;
  backface-visibility: hidden;

  transition: transform 0.7s ease-in-out;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
}
.postcard-back {
  transform: rotateY(180deg);

  padding: 30px;
}
.postcard-back .column p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.card-title {
  font-weight: 500;
}

.postcard-address {
  padding: 10vh 10px;
  p {
    border-bottom: 1px dotted;
  }
}

.click-details {
  font-size: 1rem;
}

.postcard:hover .postcard-front {
  transform: rotateY(180deg);
}

.postcard:hover .postcard-back {
  transform: rotateY(0deg);
}
.postcard-front-portfolio {
  background-color: white;
  background-image: url(../../assets/img/portfolio/favicon.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
}
.postcard-back-portfolio {
  background-color: transparent;
  background-image: url(../../assets/img/postcard-min.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}
.postcard-front-bmw {
  background-color: white;
  background-image: url(../../assets/img/BMW.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}
.postcard-back-bmw {
  background-color: transparent;
  background-image: url(../../assets/img/postcard-min.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.postcard-front-weber {
  background-color: white;
  background-image: url(../../assets/img/Weber_logo.svg);

  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
}
.postcard-back-weber {
  background-color: transparent;
  background-image: url(../../assets/img/postcard-min.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}
.postcard-front-nexxt {
  background-color: white;
  background-image: url(../../assets/img/nexxt/NEXXT-Light.png);

  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
}
.postcard-back-nexxt {
  background-color: transparent;
  background-image: url(../../assets/img/postcard-min.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}

.postcard-front-hiwi {
  background-color: white;
  background-image: url(../../assets/img/hiwi/Hof-Uni-Logo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
}
.postcard-back-hiwi {
  background-color: transparent;
  background-image: url(../../assets/img/postcard-min.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}

.postcard-front-uni {
  background-color: white;
  background-image: url(../../assets/img/uni/university-solid.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}
.postcard-back-uni {
  background-color: transparent;
  background-image: url(../../assets/img/postcard-min.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}

.postcard-front-personal {
  background-color: white;
  background-image: url(../../assets/img/personal/pp.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
}
.postcard-back-personal {
  background-color: transparent;
  background-image: url(../../assets/img/postcard-min.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}

.poststamp {
  position: absolute;
  right: 30px;
  top: 30px;
  border: 3px dotted grey;
}
.poststamp img {
  padding: 5px;
}

.is-divider-vertical {
  position: auto !important;
  margin: 0 !important;
  height: auto !important;
}

/* Project Page Responsivness */
@media screen and (max-width: 320px) {
  .project-container .columns.is-mobile {
    display: grid;
  }
  .project-container .column {
    min-width: 296px;
    min-height: 222px;
  }
  .postcard-back .columns {
    max-width: 100vw;
    max-height: 150px;
    display: flex !important;
  }
  .postcard-back .column {
    min-width: 50vw !important;
    max-width: 50vw !important;
    min-height: 150px;
    max-height: 150px;
    padding: 0.25rem;
  }
  .postcard-back .column p {
    font-size: 0.7rem;
    margin-top: 5px;
  }
  .postcard-address {
    padding: 7vh 10px !important;
  }
  .has-text-centered {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 375px) {
  .project-container .columns.is-mobile {
    display: grid;
  }
  .project-container .column {
    min-width: 351px;
    min-height: 263px;
  }
  .postcard-back .columns {
    max-width: 100vw;
    max-height: 150px;
    display: flex !important;
  }
  .postcard-back .column {
    min-width: 45vw;
    max-width: 45vw;
    min-height: 150px;
    max-height: 150px;
    padding: 0.25rem;
  }
  .postcard-back .column p {
    font-size: 0.7rem;
    margin-top: 5px;
  }
  .postcard-address {
    padding: 7vh 10px !important;
  }
  .has-text-centered {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 414px) {
  .project-container .columns.is-mobile {
    display: grid;
  }
  .project-container .column {
    min-width: 414px;
    min-height: 310px;
  }
  .postcard-back {
    .card-title {
      font-size: 1.2rem !important;
    }
  }
  .postcard-back .columns {
    max-width: 100vw;
    max-height: 190px;
    display: flex !important;
  }
  .postcard-back .column {
    min-width: 45vw;
    max-width: 45vw;
    min-height: 190px;
    max-height: 190px;
    padding: 0.25rem;
  }
  .postcard-back .column p {
    font-size: 0.8rem;
    margin-top: 10px;
  }
  .postcard-address {
    padding: 7vh 10px !important;
  }
  .has-text-centered {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 568px) {
  .project-container .column {
    min-width: 260px;
    min-height: 195px;
  }
  .postcard-back {
    .card-title {
      font-size: 1.2rem !important;
    }
  }
  .postcard-back .columns {
    max-width: 100vw;
    max-height: 190px;
    display: flex !important;
  }
  .postcard-back .column {
    min-width: 45vw;
    max-width: 45vw;
    min-height: 190px;
    max-height: 190px;
    padding: 0.25rem;
  }
  .postcard-back .column p {
    font-size: 0.8rem;
    margin-top: 10px;
  }
  .postcard-address {
    padding: 7vh 10px !important;
  }
  .has-text-centered {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 667px) {
  .project-container .column {
    min-width: 333px;
    min-height: 250px;
  }
  .postcard-back {
    .card-title {
      font-size: 1.2rem !important;
    }
  }
  .postcard-back .columns {
    max-width: 100vw;
    max-height: 190px;
    display: flex !important;
  }
  .postcard-back .column {
    min-width: 45vw;
    max-width: 45vw;
    min-height: 190px;
    max-height: 190px;
    padding: 0.25rem;
  }
  .postcard-back .column p {
    font-size: 0.8rem;
    margin-top: 10px;
  }
  .postcard-address {
    padding: 7vh 10px !important;
  }
  .has-text-centered {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 768px) {
  .project-container .column {
    min-width: 360px;
    min-height: 270px;
  }
  .postcard-back {
    padding: 5px;
    .card-title {
      font-size: 1.2rem !important;
    }
    .card-subtitle {
      font-size: 1rem !important;
    }
  }
  .postcard-back .columns {
    max-width: 100vw;
    max-height: 190px;
    display: flex !important;
  }
  .postcard-back .column {
    min-width: 22vw;
    max-width: 22vw;
    min-height: 160px;
    max-height: 160px;
    padding: 0.25rem;
  }
  .postcard-back .column p {
    font-size: 0.7rem;
    margin-top: 10px;
  }
  .postcard-address {
    padding: 4vh 10px !important;
  }
  .has-text-centered {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 896px) {
  .project-container .column {
    min-width: 448px;
    min-height: 336px;
  }
  .postcard-back {
    padding: 5px;
    .card-title {
      font-size: 1.5rem !important;
    }
    .card-subtitle {
      font-size: 1rem !important;
    }
  }
  .postcard-back .columns {
    max-width: 45vw;
    max-height: 190px;
    display: flex !important;
  }
  .postcard-back .column {
    min-width: 22vw;
    max-width: 22vw;
    min-height: 160px;
    max-height: 160px;
    padding: 0.25rem;
  }
  .postcard-back .column p {
    font-size: 0.7rem;
    margin-top: 10px;
  }
  .postcard-address {
    padding: 4vh 10px !important;
  }
  .has-text-centered {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 1024px) {
  .project-container .column {
    min-width: 468px;
    min-height: 351px;
  }
  .postcard-back {
    padding: 30px 30px 10px 30px;
    .card-title {
      font-size: 2rem !important;
    }
    .card-subtitle {
      font-size: 1.5rem !important;
    }
  }
  .postcard-back .columns {
    max-width: 45vw;
    max-height: 200px;
    display: flex !important;
  }
  .postcard-back .column {
    min-width: 22vw;
    max-width: 22vw;
    min-height: 200px;
    max-height: 200px;
    padding: 0.25rem;
  }
  .postcard-back .column p {
    font-size: 0.7rem;
    margin-top: 20px;
  }
  .postcard-address {
    padding: 4vh 10px !important;
  }
  .has-text-centered {
    font-size: 1rem;
  }
}
@media screen and (min-width: 1280px) {
  .project-container .column {
    min-width: 588px;
    min-height: 441px;
  }
  .postcard-back .columns {
    margin-top: 2rem;
    min-height: 60%;
  }
  .postcard-back .column {
    min-width: 220px;
    min-height: 60%;
  }
  .postcard-back .column p {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 1440px) {
  .project-container .column {
    min-width: 660px;
    min-height: 495px;
  }
  .postcard-back .columns {
    margin-top: 2rem;
    min-height: 65%;
  }
  .postcard-back .column {
    min-width: 268px;
    min-height: 65%;
  }
  .postcard-back .column p {
    font-size: 0.9rem;
  }
}
/* only one column for less than 414px */
@media screen and (min-width: 415px) {
  .project-container .columns.is-mobile {
    display: flex;
  }
}
@media screen and (max-width: 414px) {
  .project-container .columns.is-mobile {
    display: grid;
  }

  .postcard-back {
    padding: 10px;
    .card-subtitle {
      font-size: 1rem !important;
    }
  }
}
