.custom-navbar {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  top: 0px;
  width: 100%;
  background-color: transparent;
  vertical-align: middle;
  z-index: 10;
}

.custom-navbar .columns {
  align-items: center;
}

.custom-navbar .brand {
  font-size: 2rem;
  font-family: "Shadows Into Light", cursive;
}

#nav-menu {
  display: none;
}
/*
    @media screen and (max-width: 600px) {
      #nav-about,
      #nav-projects {
        display: none;
      }
    
      #nav-menu {
        float: right;
        display: block;
      }
    }
    */
