.is-me {
  box-shadow: 2px 2px 4px grey;
}

.about .is-size-3 {
  font-family: "Shadows Into Light", cursive !important;
}

.about .fa-ul {
  margin-left: 1.5rem;
}

.skill-list span {
  font-size: 0.5rem;
  width: 0;
  top: 1em;
}
.edu-list li:not(:last-child) {
  margin-bottom: 10px;
}
/* .exp-list {
  margin-left: 2rem !important;
} */

.exp-list li {
  margin-bottom: 10px;
  margin-left: 10px;
}
.exp-list span {
  top: 15%;
  left: -2.5rem;
}
.icon-tools figure {
  margin: 3px;
}
.icon-tools img {
  height: 48px;
  width: auto;
}

.tile-about {
  padding: 10px;
}

.toolbox-content {
  padding: 10px 22px 22px 22px;
}

.contact-details {
  padding: 20px 10px 10px 10px;
}

.photo-caption {
  position: absolute;
  top: 89%;
  width: 100%;
  font-family: "Shadows Into Light", cursive !important;
  font-size: 1.3rem;
}

@media screen and (max-width: 896px) {
  .is-mobile {
    margin: 0px;
  }
  .is-full-mobile {
    margin: 0.75rem;
  }

  .about {
    margin: 0px 10px;
  }
  #title {
    margin-left: 0px;
  }
  .tile.is-ancestor {
    margin-left: 0px;
    margin-right: 0px;
  }
}
