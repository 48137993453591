.social-section {
  padding-bottom: 2rem;
  display: inline-flex;
  font-size: 2.5rem !important;
  padding: 1rem !important;
  padding-bottom: 0.5rem !important;
}

#social-section-a,
#social-section-b,
#social-section-c {
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
#social-section-c {
  padding-right: 0px;
}

.footer {
  background-color: transparent;
}

.impressum {
  border: 0;
  font-family: "Raleway", sans-serif !important;
  font-size: 1rem;
  background: transparent;
}

.impressum:hover {
  cursor: pointer;
}

.modal-card {
  .modal-card-head,
  .modal-card-foot,
  .modal-card-body {
    background-image: url("../../assets/img/paper.jpg");
    border: 0;
    font-family: "Raleway", sans-serif !important;
  }
  .modal-card-title {
    font-family: "Shadows Into Light", cursive;
  }
}
